import { useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../utils/constants';
import { FaEnvelope } from "react-icons/fa";
import { FaBell, FaCheck, FaCubes, FaFileLines, FaGauge, FaTriangleExclamation, FaUser } from "react-icons/fa6";
import { TbLogout } from "react-icons/tb";
import { GrDocumentUpdate } from "react-icons/gr";
import { LuFileSearch2 } from "react-icons/lu";
import { Image } from 'react-bootstrap';

function TopNav() {
  const { signOut } = useContext(AuthContext);
  let loginUser = sessionStorage.getItem("userName");
  
  return(
    <div id="topnav">
      <nav className="navbar fixed-top navbar-expand-md bg-white text-dark shadow-sm ps-2">
        <div className="container-fluid">
          <Image src="./logo.jpg" className="logo img-fluid me-2 mb-1" />
          <Link className="navbar-brand" to="/"></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav me-auto">
            </ul>
            {/* <ul className="navbar-nav me-auto">
              <li className="nav-item px-3 py-1">
                <NavLink to="/" className="d-flex align-items-center nav-link">
                  <FaGauge />                  
                  <span className="ms-1 nav-link-text">Dashboard </span>
                </NavLink>
              </li>
              <li className="nav-item px-3 py-1">
                <NavLink to="/supply-status" className="d-flex align-items-center nav-link">
                  <FaCubes />
                  <span className="ms-1 nav-link-text">Supply</span>
                </NavLink>
              </li>
            </ul> */}
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {loginUser} <FaUser />
                </Link>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <button className="dropdown-item dropdown_item-3 pe-1" onClick={signOut}>
                      Logout <TbLogout />
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

const OffCanvas = (props)=> {

  async function scrollChatPage(){
    const El = await document.getElementById('chatArea');
    if(El) {
      const scrollToBottom = (node) => {
      	node.scrollTop = node.scrollHeight;
      }
      scrollToBottom(El);
    }
  }

  useEffect(()=>{
    scrollChatPage();
  },[])

  return(
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header border-bottom">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">{props.header}</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div id="chatArea" className="offcanvas-body mb-1">
        {props.body}
      </div>
      <div className="sticky-bottom">
        {props.footer}
      </div>
    </div>
  );
}

export { TopNav, OffCanvas };