import React, { Component, useState, useEffect, useCallback, useMemo } from 'react';
import { postData, postFile, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Form, ListGroup, Button, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaTrashAlt } from "react-icons/fa";
import { FaPlus, FaTrashCan, FaFileExcel, FaXmark } from "react-icons/fa6";
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { toast } from 'react-toastify';
/*
show cards of supply invoices. 4 cards per row
show thumbnail of the supply invoice in the card body
show invoice no, warehouse, supply date, status in the card footer
on click of the card, show the supply invoice details
*/

function Dashboard() {

  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isLoading, setLoading] = useState(true);

  async function getSupplyInvoices(){
    try{
      const response = await fetch('/api/supplies');
      const json = await response.json();
      console.log(json);
      setInvoices(json.length?json:[]);
    }catch(e){
      console.error(e);
    }finally{
      setLoading(false);
    }
  }  

  //function to select a supply invoice
  const selectSupplyInvoice = (row, event) => {
    const invoice = invoices.filter(e=> e.id===row.id)[0];
    setSelectedInvoice(invoice);
  }

  const addNew = ()=> {
    const invObj = {
      id           : 0,
      warehouse_id : '',
      invoice_no   : '',
      supply_date  : moment().format("YYYY-MM-DD"),
      tr_no        : '',
      tr_date      : moment().format("YYYY-MM-DD")
    };
    setSelectedInvoice(invObj);
  }

  const deleteSupply = (Ids)=> {
    deleteItem(`/api/supply?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      getSupplyInvoices();
    }).catch(e=> toast.error("Invoice already mapped to some other activities!"));
  }

  useEffect(() => {
    //get the supply invoices from the database
    getSupplyInvoices();
  }, []);

  return (
    <Container fluid className="h-100">
      {
        selectedInvoice ? (
          <InvoiceDetails data={selectedInvoice} delete={deleteSupply} refresh={()=> getSupplyInvoices()} close={() => setSelectedInvoice(null)} />
        ) : (
        <>
          <div className="h-100">
            <InvoiceList data={invoices} delete={deleteSupply} selectInvoice={selectSupplyInvoice} />
            <div className="position-relative">
              <Button type="button" variant="primary" className="fw-semibold position-fixed bottom-0 end-0 m-5" onClick={addNew}>
                <FaPlus className="text-light fs-4 me-1" />
                Add Invoice
              </Button>
            </div>
          </div>          
        </>
        )
      }      
    </Container>
  );
}


function InvoiceList(props) {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({ searchText: "", statusValue: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);  
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleFilterChange = (e)=> {
    const name = e.target.name;
    const value = e.target.value;    
    
    let filteredItems = [];
    
    if(name=="searchText"){
      setFilter({ [name]: value, 'statusValue': "" });
      filteredItems = props.data.filter(item => {
        const invoiceNoMatches = item.invoice_no && item.invoice_no.toLowerCase().includes(value.toLowerCase());
        const warehouseMatches = item.warehouse_name && item.warehouse_name.toLowerCase().includes(value.toLowerCase());    

        return invoiceNoMatches || warehouseMatches;
      });    
    }else if(name=="statusValue"){
      setFilter({ [name]: value, 'searchText': "" });
      if(value) filteredItems = props.data.filter(item=> item.is_approved===parseInt(value));
      else filteredItems = props.data;
    }
    setData(filteredItems);
  }

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filter.searchText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilter((prevItems)=> ({
          ...prevItems,
          "searchText": ""
        }));
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Supply_Invoices_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }    

    return (
      <div className="d-flex justify-content-end align-items-center">
        <InputGroup size="sm" className="px-2">
          <Form.Control name="fromDate" type="date" className="shadow-none" /> 
          <Form.Control name="toDate" type="date" className="shadow-none" />
          <Form.Select
            name="statusValue"
            className="shadow-none"
            value={filter.statusValue}
            onChange={handleFilterChange}
          >
            <option value="">-- Filter by Status --</option>
            <option value="0">Pending</option>
            <option value="1">Accepted</option>
          </Form.Select>          
          <Form.Control
            type="text"
            name="searchText" 
            className="shadow-none" 
            placeholder="Search by Invoice No., Warehouse" 
            aria-label="Search area"          
            value={filter.searchText} 
            onChange={handleFilterChange}              
            aria-describedby="inputGroup-sizing-default"
          />
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FaXmark />
          </button>  
        </InputGroup>        
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FaFileExcel className="pe-2 fs-5" /> Export
          </button>
        </div>
      </div>
    );
  }, [filter.searchText, filter.statusValue, resetPaginationToggle]);

  const columns = [      
      {
          name: 'INVOICE NO',
          selector: row => row.invoice_no,
          sortable: true,
      },
      {
          name: 'INVOICE DATE',
          selector: row => row.invoice_date,
          sortable: true,
      },      
      {
          name: 'WAREHOUSE',
          selector: row => row.warehouse,
          sortable: true,
      },      
      {
          name: 'STATUS',
          selector: row => row.status,
          sortable: true,
      },      
  ];

  const tableData = data.map((item, index)=>{
    return {
      id          : item.id,      
      invoice_no  : item.invoice_no,
      invoice_date: moment(item.supply_date).format("DD/MM/YYYY"),
      warehouse   : item.warehouse_name,
      status      : <span className={item.is_approved?"text-primary":"text-danger"}>{item.is_approved?"Accepted":"Pending"}</span>
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0 text-secondary"> Supply Invoices</h4> 
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete invoices:\r ${selectedRows.map(r => r.invoice_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));            
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FaTrashCan /> Delete
      </button>
    );
  }, [tableData, selectedRows, toggleCleared]);

  useEffect(()=>{
    if(props.data.length){
      setData(props.data);
    }
  },[props.data])   
  
  return(
    <>      
      <div className="my-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={tableData}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="440px" //remove this if you dont need table scroll                                    
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"
            pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared} 
            onRowClicked={props.selectInvoice}                         
          />
        </div>
      </div>
    </>
  );
}

const supplyItemObj = {
  product_id: "",
  product_name: "",
  units: ""
}

class InvoiceDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      invoice : props.data,
      lineItems: Array(8).fill(supplyItemObj),
      warehouses: [],
      products: [],
      files: [],
      fileType: "",
      isLoading : true,    
    };            
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLineItemsChange = this.handleLineItemsChange.bind(this);
    this.handleFileSubmit = this.handleFileSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getInvoiceDetails(){
    try{
      const response = await fetch(`/api/supply-items/${this.props.data.id}`);
      const json = await response.json();
      const updatedItems = this.state.lineItems.map((item, index) => (index < json.length ? { 'product_id': json[index].product_id, 'product_name': json[index].product_name, 'units': json[index].units } : item));      
      this.setState({ lineItems: updatedItems});   
    }catch(e){
      console.error(e);
    }finally{
      this.setState({isLoading: false});
    }
  }  

  async getWarehouses(){
    try{
      const response = await fetch('/api/warehouses');
      const json = await response.json();
      this.setState({ warehouses: json.length?json:[] });
    }catch(e){
      console.error(e);
    }
  }

  async getProducts(){
    try{
      const response = await fetch('/api/vendor-products');
      const json = await response.json();
      this.setState({ products: json.length?json:[] });
      console.log(json);
    }catch(e){
      console.error(e);
    }
  }  

  async getSupplyFiles(){
    try{
      const response = await fetch(`/api/supply-files/${this.props.data.id}`);
      const json = await response.json();
      this.setState({ files: json.length?json:[] });
      console.log(json);
    }catch(e){
      console.error(e);
    }
  }

  handleInputChange(e){
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      invoice: {
        ...this.state.invoice,
        [name]: value
      }
    });
  }

  handleLineItemsChange(e){
    const name = e.target.name;
    const value = e.target.value;
    const index = e.target.attributes.dataindex.value;        
    
    var updatedLineItems = this.state.lineItems;
    if(name==="product_name"){
      const filteredProducts = this.state.products.filter(e=> e.product_name=== value);
      if(filteredProducts.length){
        const existingItem = this.state.lineItems.filter(e=> e.product_id===filteredProducts[0].product_id);
        if(existingItem.length){
          toast.warning("Item is already in the list.")
        }else{
          updatedLineItems[index] = {
            [name]     : value,
            product_id : filteredProducts[0].product_id,
            units      : this.state.lineItems[index].units
          };        
        }        
      }else{
        updatedLineItems[index] = { product_id: "", product_name: "", units: "" };
      }
    }else{
      updatedLineItems[index] = {
        ...this.state.lineItems[index],
        [name] : value
      };      
    }
    this.setState({
      lineItems: updatedLineItems
    }); 
  }

  handleSubmit(e){
    e.preventDefault();
    const productItems = this.state.lineItems.filter(e=> e.product_id && e.units);
    const supplyData = { invoice: this.state.invoice, lineItems: productItems };
    this.setState({ isLoading: true });
    if(productItems.length){
      if(supplyData.invoice.id){
        updateData('/api/supply/'+supplyData.invoice.id, supplyData)
          .then((response) => {
            toast.success(response.message);
            this.props.close();
            this.props.refresh();
        }).catch(e=> toast.error(e));
      }else{
        postData('/api/supply', supplyData)
          .then((response) => {
            toast.success(response.message);
            this.props.close();
            this.props.refresh();
        }).catch(e=> toast.error(e));
      }      
    }else{
      toast.warning("Lineitems empty! Atleast a single item is needed to save.")
    }
  }

  handleFileChange(e){
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value  });
  }

  handleFileSubmit(e){
    e.preventDefault();
    let files = document.getElementById("supply-files").files;
    let supplyFields = document.getElementById("supply-invoice-fields");
    if(this.state.invoice.id){
      if(files.length && this.state.fileType){        
        supplyFields.disabled = true;
        let formData = new FormData();   
        
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
        formData.append("fileType", this.state.fileType);
        postFile(`/api/supply-upload/${this.state.invoice.id}`, formData).then((response) => {
          this.getSupplyFiles();
          toast.success(response.message);
          supplyFields.disabled = false;
        }).catch(e=> toast.error(e));
      }else{
        toast.warning("Select the File and FileType first!");
      }
    }else{
      toast.warning("You need to create the invoice first!");
    }    
  }  

  componentDidMount(){
    this.setState({invoice: this.props.data});
    this.getWarehouses();
    this.getProducts();
    if(this.props.data.id){
      this.getInvoiceDetails();
      this.getSupplyFiles();
    }
  }

  render(){
    const data = this.state.invoice;
    
    return (
      <Row xs={12} md={12} className="h-100">
        <Col xs={12} md={6} className="pt-2">
          <Card className="w-100 h-100 border-0 shadow">
            <Card.Header className="text-secondary fw-bold">
              Supply Information
            </Card.Header>
            <Card.Body>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <Row>
                  <Col>
                    <div>
                      <label htmlFor="warehouse_id" className="form-label text-muted">Warehouse</label>
                      <select className="form-select shadow-none" name="warehouse_id" value={data.warehouse_id} onChange={this.handleInputChange} required>
                        <option value="">Select Warehouse</option>
                        {
                          this.state.warehouses.map(row=>
                            <option key={"WH"+row.id} value={row.id}>{row.warehouse_name}</option>
                          )
                        }                      
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <label htmlFor="invoice_no" className="form-label text-muted">Invoice No.</label>
                      <input type="text" className="form-control shadow-none" name="invoice_no" value={data.invoice_no} onChange={this.handleInputChange} required />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <label htmlFor="supply_date" className="form-label text-muted">Supply Date</label>
                      <input type="date" className="form-control shadow-none" name="supply_date" value={moment(data.supply_date).format('YYYY-MM-DD')} onChange={this.handleInputChange} required />
                    </div>
                  </Col>
                </Row>
                 <Row className="my-2">
                  <Col>
                    <div>
                      <label htmlFor="tr_no" className="form-label text-muted">Transport Receipt No.</label>
                      <input type="text" className="form-control shadow-none" name="tr_no" value={data.tr_no} onChange={this.handleInputChange} required />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <label htmlFor="tr_date" className="form-label text-muted">TR Date</label>
                      <input type="date" className="form-control shadow-none" name="tr_date" value={moment().format('YYYY-MM-DD')} onChange={this.handleInputChange} required />
                    </div>
                  </Col>
                </Row>
                <ListGroup variant="flush mt-2">
                  <ListGroup.Item className="bg-secondary text-light">
                    <Row xs={12} md={12}>
                      <Col xs={9} md={9} className="">Product Name</Col>
                      <Col xs={3} md={3} className="text-end">Quantity</Col>
                    </Row>
                  </ListGroup.Item>
                  {this.state.lineItems.map((row, index) => (
                    <ListGroup.Item key={"LI"+index} className="p-0">
                      <Row xs={12} md={12} className="g-0">
                        <Col xs={9} md={9}>
                          <Form.Control type="text" name="product_name" value={row.product_name} onChange={this.handleLineItemsChange} dataindex={index} size="sm" className="shadow-none rounded-0" list="product-list" />
                        </Col>
                        <Col xs={3} md={3} className="text-end">
                          <Form.Control type="number" name="units" value={row.units} onChange={this.handleLineItemsChange} dataindex={index} size="sm" className="shadow-none rounded-0" />
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <Button type="submit" variant="primary" className="m-2">Save</Button>
                <Button type="button" variant="danger" onClick={()=> this.props.delete(data.id)} className="m-2">Delete</Button>
                <Button type="button" variant="secondary" className="m-2" onClick={this.props.close}>Cancel</Button>
              </form>
              <datalist id="product-list">
                {this.state.products.map(row=> 
                  <option key={"PI"+row.id} value={row.product_name} />
                )}
              </datalist>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} className="pt-2">
          <Card className="w-100 h-100 border-0 shadow">
            <Card.Header className="text-secondary fw-bold">
              Upload Invoice Attachments
            </Card.Header>
            <Card.Body>                                    
              <form id="supply-invoice-form" encType="multipart/form-data" onClick={this.handleFileSubmit}>
                <fieldset id="supply-invoice-fields" className="my-0 mx-auto">
                  <div className="mb-3">
                    <p className="text-muted fw-light">File must be in (.png / .jpg / .jpeg) format</p>                          
                    <input type="file" id="supply-files" className="form-control" onClick={(e) => e.stopPropagation()} multiple required /> 
                  </div>
                  <Row xs={12}>
                    <Col xs={12}sm={6} className="my-2">                      
                      <select name="fileType" className="form-select" onClick={(e) => e.stopPropagation()} onChange={this.handleFileChange} value={this.state.fileType} required>
                        <option value="">-- File Type --</option>
                        <option value="supply_invoice">Supply Invoice</option>
                        <option value="tr_receipt">Transport Receipt</option>
                      </select>                      
                    </Col>
                    <Col xs={12} sm={6} className="my-2">
                      <input type="submit" className="btn btn-outline-primary fw-bold" value="Upload and Update" />
                    </Col>
                  </Row>
                </fieldset>
              </form> 
            </Card.Body>
            <Card.Body className="overflow-y-scroll" style={{height: '50vh'}}>
              <Row xs={12} sm={6}>
              {
                this.state.files.map((file,index)=>
                  <Col key={"File"+file.id} xs={12} sm={6}>
                    <img src={file.file_path} className="img-fluid" alt={file.file_path} />
                  </Col>
                )
              }
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Dashboard;